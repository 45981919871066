import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';
import { WidgetSateProps } from 'types/widgets';


const initialState: WidgetSateProps = {
  error: null,
  deptInstallments: undefined,
  patientLoading: false,
  patientCounts: { day: 0, week: 0, month: 0 },
  treatmentCounts: { day: 0, week: 0, month: 0 },
  treatmentTotals: {
    day: { total: 0, calculatedCounts: [0], counts: [0], description: [""] },
    week: { total: 0, calculatedCounts: [0], counts: [0], description: [""] },
    month: { total: 0, calculatedCounts: [0], counts: [0], description: [""] }
  }
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setWidgetInstallmentSuccess(state, action) {
      state.deptInstallments = action.payload;
    },
    setWidgetPatientLoadingSuccess(state, action) {
      state.patientLoading = action.payload;
    },
    setWidgetPatientCountSuccess(state, action) {
      state.patientCounts = action.payload;
    },
    setWidgetTreatmentCountSuccess(state, action) {
      state.treatmentCounts = action.payload;
    },
    setWidgetTreatmentTotalCountSuccess(state, action) {
      state.treatmentTotals = action.payload;
    },
  }
});

export default slice.reducer;

export function getWidgetInstallment() {
  return async () => {
    try {
      const response = await axios.get(`api/account-motions/widget/installments`);
      dispatch(slice.actions.setWidgetInstallmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(false));
    }
  };
}

export function getWidgetPatientsCount() {
  return async () => {
    try {
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(true));
      const response = await axios.get(`api/patients/widgets/patients`);
      dispatch(slice.actions.setWidgetPatientCountSuccess(response.data));
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(false));
    }
  };
}

export function getWidgetTreatmentsCount() {
  return async () => {
    try {
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(true));
      const response = await axios.get(`api/treatments/widgets/treatments`);
      dispatch(slice.actions.setWidgetTreatmentCountSuccess(response.data));
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setWidgetPatientLoadingSuccess(false));
    }
  };
}

export function getWidgetTreatmentsTotalCount() {
  return async () => {
    try {
      const response = await axios.get(`api/treatments/widgets/treatments/total`);
      dispatch(slice.actions.setWidgetTreatmentTotalCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
