import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));
// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));
// ==============================|| MAIN ROUTES ||============================== //

const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
const PatientList = Loadable(lazy(() => import('pages/patients/list')));
const PatientDetail = Loadable(lazy(() => import('pages/patients/detail')));
const TreatmentPage = Loadable(lazy(() => import('pages/treatment/index')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'apps',
          children: [
            {
              path: 'calendar',
              element: <AppCalendar />
            },
          ]
        },
        {
          path: 'dashboards',
          children: [
            {
              path: 'sample',
              element: <SamplePage />
            }, {
              path: 'default',
              element: <DashboardDefault />
            }, {
              path: 'analytics',
              element: <DashboardAnalytics />
            },
          ]
        },
        {
          path: 'patients',
          children: [
            {
              path: 'list',
              element: <PatientList />
            }, {
              path: 'detail/:id',
              element: <PatientDetail />
            },
          ]
        }, {
          path: 'treatments',
          children: [
            {
              path: 'detail/:id',
              element: <TreatmentPage />
            },
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
