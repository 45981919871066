// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { UserStateProps } from 'types/users';

// types

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState: UserStateProps = {
  error: null,
  doctorList: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getDoctorListSuccess(state, action) {
      state.doctorList = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getDoctorList() {
  return async () => {
    try {
      const response = await axios.get(`api/users/list`);
      dispatch(slice.actions.getDoctorListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}