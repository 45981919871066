import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { OperationTypeModel, TreatmentStateProps } from 'types/treatment';
import { OperationType } from 'types/enums/enums';

// Define the initial state
const initialState: TreatmentStateProps = {
  error: null,
  patientData: undefined,
  treatmentData: undefined,
  treatmentDetail: undefined,
  operationTypes: undefined,
  selectedOperationType: undefined,
  treatmentLoading: false,
  operationTypeWithColors: undefined,
  treatmentMasterData: undefined,
};

// Create a slice
const slice = createSlice({
  name: 'treatment',
  initialState,
  reducers: {
    hasError(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    getTreatmentSuccess(state, action: PayloadAction<any>) {
      state.patientData = action.payload.patientData;
      state.treatmentData = action.payload.treatmentData;
    },
    getOperationTypesSuccess(state, action: PayloadAction<any>) {
      state.operationTypes = action.payload;
    },
    setTreatmentDetailSuccess(state, action: PayloadAction<any>) {
      state.treatmentDetail = action.payload;
    },
    setOperationTypeColorsSuccess(state, action: PayloadAction<any>) {
      state.operationTypeWithColors = action.payload;
    },
    setOperationDescription(state, action: PayloadAction<OperationTypeModel | undefined>) {
      state.selectedOperationType = action.payload;
    },
    setTreatmentMaster(state, action: PayloadAction<any>) {
      state.treatmentMasterData = action.payload;
    },
    setTreatmentLoading(state, action: PayloadAction<boolean>) {
      state.treatmentLoading = action.payload;
    },
  },
});

// Export the reducer
export default slice.reducer;

// Export actions
export const {
  hasError,
  getTreatmentSuccess,
  getOperationTypesSuccess,
  setTreatmentDetailSuccess,
  setOperationTypeColorsSuccess,
  setOperationDescription,
  setTreatmentMaster,
  setTreatmentLoading,
} = slice.actions;

// Thunks

export const getTreatmentById = (id: string | undefined) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`api/treatments/${id}`);
    dispatch(getTreatmentSuccess(response.data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const getOperationTypes = (type: OperationType | undefined) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`api/treatments/operations/${type}/stars`);
    dispatch(getOperationTypesSuccess(response.data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const getOperationTypesWithColors = () => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`api/treatments/operation/colors`);
    const mapdata = response.data?.map((row: OperationTypeModel) => ({
      label: row.description,
      id: row.id,
    })) || [];
    dispatch(setOperationTypeColorsSuccess(mapdata));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const getTreatmentMaster = (patientId: string, masterId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(`api/treatments/master-data/${patientId}/${masterId}`);
    dispatch(setTreatmentMaster(response.data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

interface Colors {
  [key: string]: any;
}

export const getTreatmentDetailById = (patientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setTreatmentLoading(true));
    const response = await axios.get(`api/treatments/${patientId}`);
    const data = [...response.data.treatmentData];
    const myData: Colors = {};
    const districts = [...new Set(data.map(({ masterId }) => masterId))];
    districts.forEach((d: any) => {
      myData[d] = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
    });

    data.forEach(async (f: any) => {
      f.color = myData[f.masterId];
    });
    dispatch(setTreatmentDetailSuccess(data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  } finally {
    dispatch(setTreatmentLoading(false));
  }
};

export const deleteTreatmentDetail = (id: string, patientId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setTreatmentLoading(true));
    const response = await axios.delete(`api/treatments/${id}/${patientId}`);
    const data = [...response.data];
    const myData: Colors = {};
    const districts = [...new Set(data.map(({ masterId }) => masterId))];
    districts.forEach((d: any) => {
      myData[d] = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
    });

    data.forEach(async (f: any) => {
      f.color = myData[f.masterId];
    });
    dispatch(setTreatmentDetailSuccess(data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  } finally {
    dispatch(setTreatmentLoading(false));
  }
};

export const setOperationDescriptionAction = (data?: OperationTypeModel) => async (dispatch: Dispatch) => {
  try {
    dispatch(setOperationDescription(data));
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const saveTreatmentPhoto = (data?: any) => async () => {
  try {
    await axios.post(`api/treatments/photo/create`, data);
  } catch (error) {
    // Assuming no dispatch is needed for this error case.
    console.error(error);
  }
};

function handleErrorResponse(error: any, dispatch: Dispatch) {
  sendError(error, dispatch);
  dispatch(hasError(error));
}

function sendError(error: any, dispatch: Dispatch) {
  enqueueSnackbar(error?.message!, {
    variant: 'error',
    persist: false,
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });
  setTimeout(() => {
    closeSnackbar();
  }, 1000);
}
