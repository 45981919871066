import { Link } from 'react-router-dom';
import { To } from 'history';
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';
import logo from "../../assets/logo/mydentes.png"
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
    {isIcon ? <LogoIcon /> : <img style={{ width: "14rem", height: "4rem", marginTop: "10px" }} src={logo} />}
  </ButtonBase>
);

export default LogoSection;
