import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';
import { Dispatch } from 'redux';
import { AccountMotionDebtModel, AccountMotionModel, AccountMotionStateProps } from 'types/account-motion';
import { v4 as uuidv4 } from 'uuid';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

const initialState: AccountMotionStateProps = {
  error: null,
  accountMotions: undefined,
  accountMotionLoading: false,
  debt: 0,
  debtPayments: undefined,
  openTakePaymentLoading: false,
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setAccountMotionSuccess(state, action) {
      state.accountMotions = action.payload;
    },
    setAccountMotionLoadingSuccess(state, action) {
      state.accountMotionLoading = action.payload;
    },
    setAccountMotionDebt(state, action) {
      state.debt = action.payload;
    },
    setDebtPayment(state, action) {
      state.debtPayments = action.payload;
    },
    setSaveLoading(state, action) {
      state.openTakePaymentLoading = action.payload;
    },
  }
});

export default slice.reducer;

export function getAccountMotionById(id: string | undefined) {
  return async () => {
    try {

      dispatch(slice.actions.setAccountMotionLoadingSuccess(true));
      const response: any = await axios.get(`api/account-motions/${id}`);
      const responseData = response.data.records.map((row: AccountMotionModel) => {
        return {
          key: row.id,
          ...row,
        };
      });
      dispatch(slice.actions.setAccountMotionSuccess(responseData));
      dispatch(slice.actions.setAccountMotionDebt(response.data.debt!));
      dispatch(slice.actions.setAccountMotionLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDebtPayments(id: string | undefined) {
  return async () => {
    try {
      dispatch(slice.actions.setSaveLoading(true));
      const response: any = await axios.get(`api/account-motions/${id}/getdates`);
      const responseDates: any[] = response.data;
      const responseData = responseDates?.map((r) => {
        return {
          key: uuidv4(),
          ...r,
        };
      });
      const data = responseData.filter((f: AccountMotionDebtModel) => f.totalAmount?.toString().split(".")[0] !== f.paymantAmount?.toString())
      dispatch(slice.actions.setDebtPayment(data));
      dispatch(slice.actions.setSaveLoading(false));
    } catch (error) {
      dispatch(slice.actions.setSaveLoading(false));
      dispatch(slice.actions.hasError(error));
      sendError(error, dispatch);
    }
  };
}

function sendError(error: any, dispatch: Dispatch) {
  enqueueSnackbar(error?.message!, {
    variant: 'error',
    persist: false,
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });
  setTimeout(() => {
    closeSnackbar();
  }, 1000);
}