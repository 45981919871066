// project-imports
import applications from './applications';
import patients from "./patients";
import dashboard from "./dashboard";

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, applications, patients,]
};

export default menuItems;
