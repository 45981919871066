// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, People, UserSearch, } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  patient: People,
  detail: UserSearch,
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const patients: NavItemType = {
  id: 'group-patients',
  title: <FormattedMessage id="patient-menu" />,
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'list',
      title: <FormattedMessage id="patients" />,
      type: 'item',
      url: '/patients/list',
      icon: icons.patient
    }
  ]
};

export default patients;
