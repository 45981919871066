// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { PatientStateProps } from 'types/patients';

// types

// ==============================|| SLICE - PRODUCTS ||============================== //

const initialState: PatientStateProps = {
  error: null,
  patientDetail: undefined,
  imageGalleryIsLoading: undefined,
  qrCode: "",
  treatmentPhotos: [],
  patientNotes: [],
  patientNoteLoading: false,
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    setPatientSuccess(state, action) {
      state.patientDetail = action.payload;
    },
    setQrCodeSuccess(state, action) {
      state.qrCode = action.payload;
    },
    setTreatmentPhotosSuccess(state, action) {
      state.treatmentPhotos = action.payload;
    },
    setImageGalleryIsLoadingSuccess(state, action) {
      state.imageGalleryIsLoading = action.payload;
    },
    setPatientShortInfo(state, action) {
      state.patientShortInfo = action.payload;
    },
    setPatientNoteSuccess(state, action) {
      state.patientNotes = action.payload;
    },
    setPatientNoteLoadingSuccess(state, action) {
      state.patientNoteLoading = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

export function getPatientById(id: string | undefined) {
  return async () => {
    try {
      ;
      const response = await axios.get(`api/patients/${id}`);
      dispatch(slice.actions.setPatientSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientNote(id: string | undefined) {
  return async () => {
    try {
      dispatch(slice.actions.setPatientNoteLoadingSuccess(true));
      const response = await axios.get(`api/patients/${id}/note`);
      dispatch(slice.actions.setPatientNoteSuccess(response.data));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    }
  };
}

export function createPatientNote(data: any) {
  return async () => {
    try {
      dispatch(slice.actions.setPatientNoteLoadingSuccess(true));
      const response = await axios.post(`api/patients/note`, data);
      dispatch(slice.actions.setPatientNoteSuccess(response.data));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    }
  };
}

export function deletePatientNote(id: string, patientId: string) {
  return async () => {
    try {
      dispatch(slice.actions.setPatientNoteLoadingSuccess(true));
      const response = await axios.delete(`api/patients/${id}/note/${patientId}`);
      dispatch(slice.actions.setPatientNoteSuccess(response.data));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    }
  };
}

export function updatePatientNote(id: string, data: any) {
  return async () => {
    try {
      dispatch(slice.actions.setPatientNoteLoadingSuccess(true));
      const response = await axios.put(`api/patients/${id}/note`, data);
      dispatch(slice.actions.setPatientNoteSuccess(response.data));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setPatientNoteLoadingSuccess(false));
    }
  };
}

export function getQrCode(patientId?: string) {
  return async () => {
    try {
      const response = await axios.get(`api/patients/${patientId}/qr-code`);

      dispatch(slice.actions.setQrCodeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTreatmentPhotos(patientId?: string) {
  dispatch(slice.actions.setImageGalleryIsLoadingSuccess(true));
  return async () => {
    try {
      const response = await axios.get(`api/treatments/photo/${patientId}/list`);
      dispatch(slice.actions.setTreatmentPhotosSuccess(response.data));
      dispatch(slice.actions.setImageGalleryIsLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPatientShortInfo(data: any) {
  return async () => {
    try {

      const response = await axios.post(`api/patients/short/info`, data);
      dispatch(slice.actions.setPatientShortInfo(response!.data.name + " " + response!.data.surname));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTreatmentPhoto(patientId?: string) {
  dispatch(slice.actions.setImageGalleryIsLoadingSuccess(true));
  return async () => {
    try {
      const response = await axios.delete(`api/treatments/photo/${patientId}`);
      dispatch(slice.actions.setTreatmentPhotosSuccess(response.data));
      dispatch(slice.actions.setImageGalleryIsLoadingSuccess(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setQrCode(qrCode?: string) {
  return async () => {
    try {
      dispatch(slice.actions.setQrCodeSuccess(qrCode));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setTreatmentPhotos(photos?: any[]) {
  return async () => {
    try {
      dispatch(slice.actions.setTreatmentPhotosSuccess(photos));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}