import { createContext, useEffect, useReducer, ReactElement } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
// reducer - state management
import { LOGIN, LOGOUT } from '../store/reducers/actions';
import authReducer from '../store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType } from 'types/auth';
import { openSnackbar } from 'store/reducers/snackbar';

const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

const explicitUrls = ['take-photo'];

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {

    const init = async () => {
      if (explicitUrls.includes(window.location?.pathname.split('/')[1])) {
        dispatch({
          type: LOGOUT
        });
      } else {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get('/api/users/me');
          const user = response.data.data;

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      }
    };
    init();
  }, [!explicitUrls.includes(window.location?.pathname.split('/')[1])]);

  const navigate = useNavigate();
  const login = async (phoneNumber: string, password: string) => {
    const response = await axios.post('/api/auth/login', { username: phoneNumber, password });
    const res = response.data;
    if (res.newLogin) {
      navigate(`/confirm-register/${res.hashId}`, { replace: true });
      return
    }
    setSession(res.token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: res
      }
    });
  };

  const confirmLogin = async (apikey: string, password: string, code: string) => {
    const response = await axios.patch(`api/users/${apikey}/register-password`, { code, password });
    const res = response.data;

    if (res.success === false) {
      dispatch(
        openSnackbar({
          open: true,
          message: res.data.error,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return;
    }

    setSession(res.token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: res
      }
    });
    navigate("/login");
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (phoneNumber: string) => {
    await axios.post(`/api/users/${phoneNumber}/refresh-password`, {
      phoneNumber
    });
  };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, confirmLogin, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
