// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, People, UserSearch, MusicDashboard } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  patient: People,
  detail: UserSearch,
  musicDashboard: MusicDashboard
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const dashboards: NavItemType = {
  id: 'group-dashboards',
  title: <FormattedMessage id="dashboards-menu" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    //   id: 'sample',
    //   title: <FormattedMessage id="dashboards" />,
    //   type: 'item',
    //   url: '/dashboards/sample',
    //   icon: icons.musicDashboard
    // }, 
    {
      id: 'default',
      title: <FormattedMessage id="default" />,
      type: 'item',
      url: '/dashboards/default',
      icon: icons.musicDashboard
    },
    // {
    //   id: 'analytics',
    //   title: <FormattedMessage id="analytics" />,
    //   type: 'item',
    //   url: '/dashboards/analytics',
    //   icon: icons.musicDashboard
    // }
  ]
};

export default dashboards;
